h5 {
  margin-bottom: 0;
}

.bg-gradient {
  background-image: linear-gradient(to right, #3b00cf, #9c42d9);
}

.justify-content-center {
  align-items: center;
  justify-content: center;
}

.product-header-title {
  font-size: 63px;
  font-weight: 900;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-header-title {
    font-size: 46px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-header-title {
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .product-header-title {
    font-size: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .product-header-title {
    font-size: 22px;
  }
}

.product-header-title-description {
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .product-header-title-description {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .product-header-title-description {
    font-size: 10px;
  }
}

.product-footer .picture {
  width: 70%;
  margin-top: -23%;
}

.product-footer p {
  width: 70%;
  font-size: 22px;
  font-weight: 500;
  margin: 25px auto;
}
@media only screen and (max-width: 576px) {
  .product-footer p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .product-footer p {
    width: 95%;
  }
}

.controle360-video {
  width: 600px;
  display: block;
  margin: 10px auto;
}
@media only screen and (max-width: 767px) {
  .controle360-video {
    width: 700px;
  }
}
@media only screen and (max-width: 575px) {
  .controle360-video {
    width: 350px;
  }
}

/* product 360 controle */
.controle360-the-problem .div-1 img {
  height: 200px;
}

.controle360-the-problem .div-2 p {
  font-weight: bold;
  font-size: x-large;
}

.controle360-the-problem .div-3 {
  position: relative;
  text-align: center;
  color: white;
}

.controle360-the-problem .div-3 img {
  height: 600px;
}
@media only screen and (max-width: 479px) {
  .controle360-the-problem .div-3 img {
    height: 236px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 576px) {
  .controle360-the-problem .div-3 img {
    height: 300px;
  }
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
  .controle360-the-problem .div-3 img {
    height: 350px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .controle360-the-problem .div-3 img {
    height: 450px;
  }
}

.controle360-the-problem .div-3 p {
  width: 55%;
  font-size: x-large;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 767px) {
  .controle360-the-problem .div-3 p {
    width: 90%;
    font-size: small;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .controle360-the-problem .div-3 p {
    width: 80%;
    font-size: medium;
  }
}

.controle360-features .feature {
  position: relative;
  text-align: center;
}

.controle360-features .feature .content {
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  padding: 20px;
}

.usecases {
  margin-top: 8%;
}

.usecases .usecase {
  text-align: center;
  border: 1px solid white;
  border-radius: 8px;
  padding: 30px 15px;
}

.usecases .usecase img {
  width: 70px;
  margin-bottom: 20px;
}

.usecases .usecase .content {
  color: white;
}

.controle360-screenshot-1 .description {
  margin-top: 150px;
}
@media only screen and (max-width: 991px) {
  .controle360-screenshot-1 .description {
    margin-top: 0;
  }
}

.controle360-screenshot-1 .description img {
  margin-left: -300px;
}

/* ai fraude */
.product-header-img {
  margin-top: -10%;
}

.product-header-img img,
.chatbot img {
  max-width: 70%;
}

.overview {
  margin-top: 100px;
}

.overview .div-1 img {
  height: 200px;
}

.overview .div-2 p {
  font-size: 22px;
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .overview .div-2 p {
    font-size: 14px;
  }
}

.overview .div-3 {
  width: 800px;
  height: 500px;
  background-size: cover;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  color: white;
}

.overview .controle360 {
  background-image: url("/assets/images/product/p1/img10.png");
}

.overview .ai-fraud {
  background-image: url("/assets/images/product/p2/img1.png");
}

.overview .plv {
  background-image: url("/assets/images/product/p3/img1.png");
}

.overview .chatbot {
  background-image: url("/assets/images/product/p4/img1.png");
}

@media only screen and (max-width: 576px) {
  .overview .div-3 {
    width: 100%;
    height: 250px;
  }
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
  .overview .div-3 {
    width: 100%;
    height: 350px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview .div-3 {
    width: 100%;
    height: 450px;
  }
}

.overview .div-3 p {
  font-size: 30px;
}
@media only screen and (max-width: 576px) {
  .overview .div-3 p {
    font-size: small;
  }
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
  .overview .div-3 p {
    font-size: medium;
  }
}

.card-features .feature {
  height: 500px;
  background-size: cover;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 16px;
}

/* controle360 */
.card-features .f1 {
  background-image: url("/assets/images/product/p1/img6.png");
}

.card-features .f2 {
  background-image: url("/assets/images/product/p1/img7.png");
}

.card-features .f3 {
  background-image: url("/assets/images/product/p1/img5.png");
}

/* ai fraud  */
.card-features .card-f1 {
  background-image: url("/assets/images/product/p2/img5.png");
}

.card-features .card-f2 {
  background-image: url("/assets/images/product/p2/img6.png");
}

.card-features .card-f3 {
  background-image: url("/assets/images/product/p2/img7.png");
}

.card-features .card-f4 {
  background-image: url("/assets/images/product/p2/img8.png");
}

.card-features .card-f5 {
  background-image: url("/assets/images/product/p2/img9.png");
}

.card-features .card-f6 {
  background-image: url("/assets/images/product/p2/img10.png");
}

.card-features .feature .content {
  height: 185px;
  color: white;
  padding: 30px 8px;
  align-self: flex-end;
}

.ai-fraud-usecases {
  margin-top: 8%;
}

.ai-fraud-usecases h3 {
  width: 90%;
  margin: 0 auto;
}

.ai-fraud-usecases .usecase {
  text-align: center;
  border: 1px solid white;
  border-radius: 8px;
  padding: 20px 0;
}

.ai-fraud-usecases .usecase p {
  color: white;
}

.ai-fraud-outline {
  width: 70%;
  margin: 0 auto;
}
@media only screen and (max-width: 576px) {
  .ai-fraud-outline {
    width: 90%;
  }
}

.ai-fraud-outline p {
  font-size: 22px;
  font-style: italic;
}
@media only screen and (max-width: 576px) {
  .ai-fraud-outline p {
    font-size: 14px;
  }
}

/* --------------------------- END PAGE --------------------------- */
/* plv */
.plv-screenshot {
  margin-bottom: 25%;
}

/* --------------------------- END PAGE --------------------------- */
/* chatbot */
.chatbot-screenshot-2 {
  margin-bottom: 25%;
}
