/* Experience & Degree  */

.container-resume {
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: max-content auto auto auto;
  grid-row-gap: 1em;
  grid-column-gap: 20px;
  margin-right: 40px;
  /* grid-template-rows: auto;
    justify-items: center;
    align-items: center; */
  /* width: max-content; */
  /* border: 2px 0px 0px 0px solid rgb(202, 104, 104); */
  /* border: 2px solid rgb(202, 104, 104); */
}

/* .header:after {
    border-bottom: 1px solid #20A8DB;
    content: '';
    display: block;
    margin-left: 25%;
    width: 10%;
  }  */

.experience-title {
  font-weight: 600;
}

.header-resume {
  border-bottom: 2px solid #2B05A0;
  grid-column-start: 1;
  grid-column-end: -1;
  /* grid-column: span 4; */
  /* display: flex; */
  /* grid-column-start: 1;
    grid-column-end: 4; */
  /* border: 2px solid rgb(245, 245, 245); */
  /* grid-column: span; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.year-period {
  /* border: 2px solid rgb(202, 104, 104); */
  /* grid-column-start: 1;
    grid-column-end: -1; */
}

.content-resume {
  grid-column-start: 2;
  grid-column-end: -1;
  border-bottom: 2px solid rgb(206, 206, 206);
  /* grid-column: span 3; */
  display: grid;
  grid-template-columns: auto;
}


.content-skills {
  grid-column-start: 2;
  grid-column-end: -1;
  /* border-bottom: 2px solid rgb(206, 206, 206); */
  /* grid-column: span 3; */
  display: grid;
  grid-template-columns: auto;
}

.header-experience {
  /* border: 2px solid rgb(202, 104, 104); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-experience .buttons {
  /* float: right; */
  margin-right: 0px;
}

.popup-content {
  overflow-y: visible;
  overflow-x: visible;
}

.header h4 {
  color: #2B05A0;
}