/* .react-grid-Canvas{
    position: absolute; top: 0px; left: 0px; overflow: auto scroll; width: min-content; height: 313px;background:rgb(156, 76, 76);
} */

.react-grid-Container{
border-top-left-radius:50px;background: blue ;
}

.react-grid-Main{
    /* border-top-left-radius:50px; */
}

.react-grid-Grid{
    /* border-top-left-radius:50px; */
}

.react-grid-Header{
    /* border-top-left-radius:50px; */
}

.react-grid-Cell:nth-child(1){
    width: max-content;
}

.react-grid-Cell:nth-child(7){
    background: rgb(109, 109, 109);
}

/* div.react-grid-Row:nth-child(1) div.react-grid-Cell:nth-of-type(2){
    background: black;
}

div.react-grid-Row:nth-child(2) div.react-grid-Cell:nth-of-type(2){
    background: black;
} */

.react-grid-Cell:nth-child(22){
    background: rgb(109, 109, 109);
}

.react-grid-Cell:nth-child(8){
    background: rgb(109, 109, 109);
}

.react-grid-Cell-backgound{
    background-color: rgb(109, 109, 109) !important;
}

/* .react-grid-Row:nth-child(1) .react-grid-Cell:nth-child(3){
    background: rgb(109, 109, 109);
} */


/* .react-grid-Viewport{
    border-top-left-radius:50px;
}

.react-grid-Row{
    border-top-left-radius:50px;
}

.react-grid-Cell{
    border-top-left-radius:50px;
} */

